<template>
  <div>
    <div class="login_box_content">
      <div class="titleBox">
        <h2>放射影像专业质控中心</h2>
      </div>
      <el-form
        :model="ruleForm2"
        status-icon
        :rules="rules2"
        ref="ruleForm2"
        class="demo-ruleForm"
      >
        <el-form-item class="custom_item" label="帐号" prop="username">
          <el-input
            type="text"
            placeholder="账号"
            prefix-icon="el-icon-user"
            v-model="ruleForm2.username"
            auto-complete="off"
            @keyup.enter.native="submitForm('ruleForm2')"
          ></el-input>
        </el-form-item>

        <el-form-item class="custom_item" label="密码" prop="password">
          <el-input
            type="password"
            placeholder="密码"
            prefix-icon="el-icon-lock"
            v-model="ruleForm2.password"
            auto-complete="off"
            maxlength="18"
            v-on:keyup.enter.native="submitForm('ruleForm2')"
          ></el-input>
        </el-form-item>

        <el-form-item class="btn_box">
          <el-button
            class="login_btn"
            type="primary"
            @click="submitForm('ruleForm2')"
            >登 录</el-button
          >
        </el-form-item>
        <el-form-item>
          <div class="reg-box">
            <el-link :underline="false" class="forget" @click="onForget"
              >忘记密码？</el-link
            >
            <el-link :underline="false" class="register" @click="onRegister" v-if="false"
              >立即注册</el-link
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Base64 } from "js-base64";
import { LoginByPassword } from "@/api/index.js";
import { mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };

    var validateUsername = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入帐号"));
      } else {
        callback();
      }
    };

    return {
      ruleForm2: {
        username: localStorage.QCLoginName || "",
        password: localStorage.QCLoginPsd || "",
      },
      rules2: {
        username: [{ validator: validateUsername, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  methods: {
    ...mapActions(["setLoginInfoFunc"]),
    submitForm(formName) {
      const that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.loginRequest();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveInfo(data) {
      const s = Base64.encode(data.ID + ":" + data.Token);
      localStorage.setItem("QCToken", s);
      localStorage.QCLoginName = this.ruleForm2.username;
      localStorage.QCLoginPsd = this.ruleForm2.password;
      Vue.axios.defaults.headers.common["Author"] =
        localStorage.getItem("QCToken");
      // 存 store
      this.setLoginInfoFunc(data);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    loginRequest() {
      //
      const t = this;
      const u = t.ruleForm2.username;
      const p = t.ruleForm2.password;
      this.LoginByPassword({
        AccountName: u,
        Password: p,
      });
    },
    async LoginByPassword(data) {
      const r = await LoginByPassword(data);
      if (r.code === 1) {
        // console.log(r.data);
        this.saveInfo(r.data);
        this.$router.replace("/home");
      } else {
        const msg = r.error.Message;
        this.$message.error(msg);
      }
    },
    onRegister() {
      this.$router.push("/register");
    },
    onForget() {
      this.$router.push("/resetPassword");
    },
  },
  computed: {},
  mounted: function () {},
};
</script>

<style scoped>
.login_box_content {
  width: 300px;
  /*border: 1px solid #e6e6e6;*/
  border-radius: 5px;
  padding: 30px;
  margin: 0 auto;
  background-color: #ffffff;
  padding-top: 200px;
}
.titleBox {
  text-align: center;
}

.get_verify_btn {
  float: right;
}

.login_btn {
  width: 100%;
  margin-top: 20px;
}
.register_btn {
  width: 45%;
}
.n_register_btn {
  width: 100%;
}
.custom_item {
  margin-bottom: 10px !important;
}
.btn_box {
  margin-bottom: 20px;
}

.vcodeImg {
  height: 30px;
}
.append_box {
  padding: 0 10px;
}
.forget {
  color: black;
  cursor: pointer;
}
.company-info-box {
  background-color: transparent;
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 40px;
  z-index: 1000;
}
.company_name {
  padding-left: 20px;
}
.reg-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forget {
  color: #87b87f;
}
.register {
  color: #b74635;
}
</style>>